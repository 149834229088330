<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link>-->
<!--    |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
  <header-area/>
  <router-view/>
  <socials-footer/>
</template>

<script>
    import HeaderArea    from '@/components/HeaderArea';
    import SocialsFooter from './components/SocialsFooter';

    export default {
        name:       'Home',
        components: {
            SocialsFooter,
            HeaderArea }
    };
</script>
