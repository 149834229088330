<template>
    <section class="page-section">
        <div v-if="background" class="page-section__bg"
             :style="{ backgroundImage: 'url(' + background + ')' }">
        </div>
        <div class="page-section__content">
            <div class="container">
                <slot></slot>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'page-section',
        props: {
            background: {},
        },
    };
</script>
