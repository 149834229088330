import { createRouter, createWebHistory } from 'vue-router';
import Home                               from '@/views/Home.vue';

const routes = [
    {
        path:      '/',
        name:      'Home',
        component: Home
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from) => {
    if (to.name !== 'Home') {
        return { name: 'Home' }
    }
})

export default router;
