<template>
    <div class="roadmap">
        <div class="roadmap__section">
            <section>
                <div class="circle">
                    <h3>ALPHA</h3>
                    <span>100%</span>
                </div>
                <article data-step="1">
                    <header>
                        <span>Character Customization</span>
                        <em>&#10003;</em>
                    </header>
                </article>
                <article data-step="2">
                    <header>
                        <span>Worldbuilding</span>
                        <em>&#10003;</em>
                    </header>
                </article>
                <article data-step="3">
                    <header>
                        <span>NPCs & Interactions</span>
                        <em>&#10003;</em>
                    </header>
                </article>
                <article data-step="4">
                    <header>
                        <span>Combat & Quest System</span>
                        <em>&#10003;</em>
                    </header>
                </article>
                <article data-step="5">
                    <header>
                        <span>Public Alpha Release</span>
                        <em>&#10003;</em>
                    </header>
                </article>
            </section>
        </div>
        <div class="roadmap__section">
            <section>
                <div class="circle">
                    <h3>Upcoming</h3>
                    <span>-</span>
                </div>
                <article data-step="1">
                    <header>
                        <span>Actual Quests & Progression</span>
                    </header>
                </article>
                <article data-step="2">
                    <header>
                        <span>Side Quests & Characters</span>
                    </header>
                </article>
                <article data-step="3">
                    <header>
                        <span>More things to explore</span>
                    </header>
                </article>
                <article data-step="4">
                    <header>
                        <span>More Vehicles / Transportation</span>
                    </header>
                </article>
                <article data-step="5">
                    <header>
                        <span>(Maybe) More NSFW features</span>
                    </header>
                </article>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Roadmap',
    };
</script>
