<template>
  <section class="slider">
    <!-- Slides -->
    <div class="slider__content swiper-container" ref="slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
          <div class="slide-inner">
            <div class="slider-inner__bg" :style="{ backgroundImage: 'url(' + slide.background + ')' }"></div>
            <div class="container">
              <div data-swiper-parallax="300" class="slide-inner__title">
                <h1>{{ slide.title }}</h1>
              </div>
              <div data-swiper-parallax="400" class="slide-inner__description">
                <p>{{ slide.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Thumbs -->
    <div class="slider__thumbs" ref="thumbs">
      <div class="swiper-wrapper container" :style="{ '--slides-count': slides.length }">
        <div v-for="(slide, index) in slides"
             class="swiper-slide"
             :class="{ 'swiper-slide-thumb-active': index === 0 }"
             :key="slide.id">
          <div class="slider-thumbs__content"
               :style="{ '--slide-time': `${slideTimeSeconds}s` }">
            <div class="slider-thumbs__image-wrapper">
              <div class="slider-thumbs__image">
                <img :src="slide.background" alt="">
              </div>
            </div>
            <div class="slider-thumbs__text">
              {{ slide.thumb_title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Swiper from 'swiper';
  import {
    Autoplay,
    Controller,
    Thumbs,
    Virtual,
  }             from 'swiper/modules';

  export default {
    name:  'Slider',
    props: {
      slides: {
        required: true,
        type:     Array,
      }
    },
    data() {
      return {
        swiper:           null,
        slideTimeSeconds: 4.5,
        interleaveOffset: 0.5,
      };
    },
    mounted() {
      this.initSwiper();
    },
    methods: {
      initSwiper() {
        const options = {
          slideToClickedSlide: true,
          observer:            true,
          observeParents:      true,
        };

        const galleryThumbs = new Swiper(this.$refs.thumbs, {
          ...options,
          breakpoints:           {
            701:  {
              slidesPerView: 3,
            },
            1181: {
              slidesPerView: 4,
            },
          },
          slidesPerView:         1.75,
          watchSlidesProgress:   true,
          watchSlidesVisibility: true,
        });

        const swiperOptions = {
          ...options,
          modules:             [Autoplay, Controller, Thumbs, Virtual],
          speed:               1000,
          parallax:            true,
          autoplay:            {
            delay:                this.slideTimeSeconds * 1000,
            waitForTransition:    false,
            disableOnInteraction: true,
          },
          allowTouchMove:      false,
          watchSlidesProgress: true,
          controller:          {
            control: galleryThumbs,
            by:      'container',
          },
          thumbs:              {
            multipleActiveThumbs: false,
            swiper:               galleryThumbs,
          },
          on:                  {
            progress:      (swiper) => {
              for (var i = 0; i < swiper.slides.length; i++) {
                const slideProgress  = swiper.slides[i].progress;
                const innerOffset    = swiper.width * this.interleaveOffset;
                const innerTranslate = slideProgress * innerOffset;

                swiper.slides[i].querySelector('.slide-inner').style.transform =
                    'translate3d(' + innerTranslate + 'px, 0, 0)';
              }
            },
            touchStart:    (swiper) => {
              for (let i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = '';
              }
            },
            setTransition: (swiper, speed) => {
              for (let i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition                               = speed + 'ms';
                swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms';
              }
            },
            autoplayStop() {
              if (!galleryThumbs) return;

              galleryThumbs.el.classList.add('slider-thumbs--stop');
            },
          },
        };

        this.swiper = new Swiper(this.$refs.slider, swiperOptions);
      },
    },
  };
</script>
