export default [
    {
        name:                              'dark',
        '--color-primary':                 '#0894F7',
        '--color-contrast':                '#0067B0',
        '--color-contrast-highlight':      'rgb(51, 166, 211)',
        '--color-contrast-opacity':        'rgba(27, 148, 192, 0.3)',
        '--color-background':              'rgb(31, 31, 31)',
        '--color-background-light':        'rgba(31, 31, 31, 0.7)',
        '--color-background-dark':         'rgb(17, 17, 17)',
        '--color-background-contrast':     'rgba(31, 31, 31, 0.94)',
        '--color-background-overlay':      'rgba(59, 59, 59, 0.7)',
        '--color-background-overlay-dark': 'rgba(31, 31, 31, 0.29)',
        '--color-text':                    'rgb(221, 221, 221)',
        '--color-text-opacity':            'rgba(221, 221, 221, 0.5)',
        '--color-text-contrast':           'rgb(34, 34, 34)',
        '--color-error':                   'rgb(192, 65, 65)',
        '--color-border':                  'rgba(221, 221, 221, 0.1)',
        '--color-white':                   'rgb(255, 255, 255)',
        '--color-grey':                    'rgb(153, 153, 153)',
        '--color-black':                   'rgb(0, 0, 0)',
        '--color-box-shadow':              'rgba(0, 0, 0, 0.1)',
        '--color-divider':                 'rgba(153, 153, 153, 0.15)',
    },
];
