<template>
    <section class="video-player">
        <iframe src="https://www.youtube-nocookie.com/embed/IfF0ptUaxFQ?si=PlvQBKF0c1R0LHIU"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        <!--        <video v-if="enabled" controls width="100%" controlsList="nofullscreen nodownload" ref="video">-->
        <!--            <source :src="require('@/assets/gameplay_video.mp4')" type="video/mp4"/>-->
        <!--        </video>-->
        <!--        <div v-else class="video-player__overlay">-->
        <!--            <button @click="onPlayClick">-->
        <!--                <font-awesome-icon icon="fa-solid fa-play-circle"/>-->
        <!--                <span>-->
        <!--                    Show Gameplay Video-->
        <!--                </span>-->
        <!--            </button>-->
        <!--        </div>-->
    </section>
</template>

<script>
export default {
    name: 'VideoPlayer',
    data() {
        return {
            enabled: false,
        };
    },
    methods: {
        onPlayClick() {
            this.enabled = true;

            this.$nextTick(() => {
                // this.$refs.video.play();
            });
        },
    },
};
</script>

