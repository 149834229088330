import themes from './theme';

const _updateTheme = theme => {
    let selectedTheme = themes.find(t => t.name === theme);

    if (!selectedTheme) {
        console.error(`Theme '${theme}' could not be found!`);
        selectedTheme = themes[0];
    }

    const html = document.documentElement;

    Object.keys(selectedTheme).forEach((property) => {
        if (property === 'name') return;
        html.style.setProperty(property, selectedTheme[property]);
    });
};

export const updateTheme = _updateTheme;
