<template>
    <header class="header-area" :class="classes">
        <div class="header-area__content">
            <a href="#" class="header-area__content-logo">
                <img :src="require('@/assets/images/logo.png')" alt="Arctic Wolves Logo">
            </a>
            <div class="header-area__content-btns">
                <a class="btn" target="_blank" href="https://t.me/arctic_wolves_game">News & Updates</a>
                <a href="https://t.me/+An0yUhJ9bvBmYmY6" target="_blank" class="icon">
                    <font-awesome-icon icon="fa-solid fa-bell"/>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name:     'HeaderArea',
        data() {
            return {
                hasSolidBackground: false,
            };
        },
        created() {
            window.addEventListener('scroll', e => {
                const scrollY = window.scrollY;

                this.hasSolidBackground = scrollY > 150;
            });
        },
        computed: {
            classes() {
                return {
                    'header-area--bg': this.hasSolidBackground,
                };
            },
        },
    };
</script>
